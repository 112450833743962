import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingText = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [defaultValue, setDefaultValue] = useState(
    props.value ? props.value : ""
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const input = useRef(null);

  function updateSetting(value) {
    if (props.skip_update !== true) {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          skip_history: props.skip_history,
        },
      ]);
    }

    if (props.callback) {
      let old_value = props.value;
      props.callback(value, old_value);
    }
  }
  

  function formatValue() {
    let value;
    if (props.alphanumeric == true) {
      value = input.current.value.replace(/[^a-z0-9+ _-]+/gi, "_");
    } else if (props.snakecase == true) {
      value = input.current.value.replace(/[^a-z0-9+]+/gi, "_");
    } else if (props.no_commas == true) {
      value = input.current.value.replace(/,/g, "");
    } else {
      value = input.current.value;
    }

    setDefaultValue(value);
    return value;
  }

  function waitForInputEnd(e) {
    const value = formatValue();
    let timeoutIdTemp;
    clearTimeout(timeoutId);
    timeoutIdTemp = setTimeout(function () {
      updateSetting(value);
    }, 1000);
    setTimeoutId(timeoutIdTemp);
  }

  useEffect(() => {
    setDefaultValue(props.value ? props.value : '');
  }, [props.object_id, props.last_reset, props.value]);


  return (
    <React.Fragment>
      <input
        type="text"
        maxLength={props.maxLength}
        setting_name={props.setting_name}
        name={props.setting_name}
        className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
        defaultValue={defaultValue}
        value={props.no_commas == true ? defaultValue : undefined}
        ref={input}
        onFocus={(e) => {
          if (builder.displayFeatureGate(e, props.feature_gate_type) == false) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          if (props.update_on_input_end == true) {
            waitForInputEnd(e);
          }
        }}
        onBlur={(e) => {
          const value = formatValue();
          updateSetting(value);
        }}
        onKeyDown={(e) =>{
          if (e.key === "Enter") {
            const value = formatValue();
            updateSetting(value);
          }
        }}
        placeholder={props.placeholder}
      />
    </React.Fragment>
  );
};

export default VariantBuilderSettingText;
