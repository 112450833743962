import React, { useContext, useState, useEffect } from "react";
import PropTypes, { element } from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import VariantBuilderPanelBreadcrumb from "../variants/VariantBuilderPanelBreadcrumb";
import TabGroup from "../shared/TabGroup";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";

const SectionSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const preSelectedTab = builder.selected().object_tab;

  let selected = builder.selected();
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let section = builder.selected().object;
  let row_element = Object.values(variant.elements).filter(
    (element) => section.element_id == element.id
  )[0];

  const label = section.element_id ? "Row" : "Section";
  const tab = contextData.selected_object_tab || label;

  const columnLayoutValue =
    contextData.device == "mobile" &&
    section.options["structure"]["column-layout-mobile"] &&
    section.options["structure"]["mobile-responsive"] == "false"
      ? section.options["structure"]["column-layout-mobile"]
      : section.options["structure"]["column-layout"]
      ? section.options["structure"]["column-layout"]
      : "100";

  return (
    <React.Fragment>
      <div className="row hard-center">
        <VariantBuilderPanelTitle
          title={section.element_id ? "Row" : "Section"}
          object_type="sections"
          object_id={selected.id}
          object={selected.object}
          tooltip={`${
            section.element_id
              ? "Rows are containers of responsive columns that can be nested inside any other column. Choose a layout. Then, style the container and the columns."
              : "Section are containers of responsive columns. Choose a layout. Then, style the container and the columns."
          }`}
          tooltipLabel="Learn"
        />

        <TabGroup
          tabs={[`${label}`, "Columns"]}
          tab={tab}
          setActiveTab={(tab) => {
            dispatchCustomEvent("selectObject", {
              object_type: "sections",
              object_id: section.id,
              tab: tab,
            });
          }}
          className="mb-6"
        >
          {tab == label && (
            <React.Fragment>
              <VariantBuilderPanelCollapse name="Layout" open={true}>
                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][column-layout]"
                  setting_type="column"
                  object_type="sections"
                  object_id={section.id}
                  label="Column Layout"
                  value={
                    section.options["structure"]["column-layout"]
                      ? section.options["structure"]["column-layout"]
                      : "100"
                  }
                  condition={
                    contextData.device == "desktop" ||
                    section.options["structure"]["mobile-responsive"] !==
                      "false"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][column-layout-mobile]"
                  setting_type="column"
                  object_type="sections"
                  object_id={section.id}
                  label="Column Sizes"
                  value={
                    section.options["structure"]["column-layout-mobile"]
                      ? section.options["structure"]["column-layout-mobile"]
                      : section.options["structure"]["column-layout"]
                  }
                  condition={
                    contextData.device == "mobile" &&
                    section.options["structure"]["mobile-responsive"] == "false"
                  }
                  device="mobile"
                />

                <div className="col-xs-12 some-vertical-space">
                  <div className="col-xs-12 no-padding-sides">
                    <div className="w-full relative text-black dark:text-white">
                      <span className="text-xl">Columns:</span>
                    </div>
                  </div>

                  {["first", "second", "third", "fourth"].map((column, i) => {
                    return (
                      <VariantBuilderSetting
                        key={`section-${section.id}-column-${i}`}
                        object={section}
                        setting_name={`[${column}-trigger]`}
                        setting_type="item_v2"
                        object_type="sections"
                        object_id={section.id}
                        title={`Column ${i + 1}`}
                        condition={
                          column == "first" ||
                          (column == "second" &&
                            [
                              "50-50",
                              "60-40",
                              "40-60",
                              "70-30",
                              "30-70",
                              "80-20",
                              "20-80",
                              "90-10",
                              "10-90",
                              "33-33-33",
                              "50-25-25",
                              "25-25-50",
                              "25-50-25",
                              "25-25-25-25",
                            ].includes(columnLayoutValue)) ||
                          (column == "third" &&
                            [
                              "33-33-33",
                              "50-25-25",
                              "25-25-50",
                              "25-50-25",
                              "25-25-25-25",
                            ].includes(columnLayoutValue)) ||
                          (column == "fourth" &&
                            ["25-25-25-25"].includes(columnLayoutValue))
                        }
                        label_max_length={25}
                        label_hover_max_length={14}
                        style={{
                          marginBottom: "0px",
                        }}
                        label_icon={"fa-regular fa-square"}
                        onEdit={() => {
                          dispatchCustomEvent("selectObject", {
                            object_type: "sections",
                            object_id: section.id,
                            tab: "Columns",
                            collapse: column,
                          });
                        }}
                        hide_duplicate={true}
                        hide_remove={true}
                        hide_handle={true}
                      />
                    );
                  })}
                </div>

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][mobile-responsive]"
                  setting_type="checkbox"
                  object_type="sections"
                  object_id={section.id}
                  label="Mobile Responsive"
                  value={
                    section.options["structure"]["mobile-responsive"]
                      ? section.options["structure"]["mobile-responsive"]
                      : "true"
                  }
                  tooltip="Toggling this off will prevent columns from responding to mobile device screen sizes and enable column size control for mobile"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][hide-desktop]"
                  setting_type="checkbox"
                  object_type="sections"
                  object_id={section.id}
                  label="Hide on desktop"
                  value={
                    section.options["structure"]["hide-desktop"]
                      ? section.options["structure"]["hide-desktop"]
                      : "false"
                  }
                  tooltip="Want to display this only on mobile? Enable this option"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][hide-mobile]"
                  setting_type="checkbox"
                  object_type="sections"
                  object_id={section.id}
                  label="Hide on mobile"
                  value={
                    section.options["structure"]["hide-mobile"]
                      ? section.options["structure"]["hide-mobile"]
                      : "false"
                  }
                  tooltip="Want to display this only on desktop? Enable this option"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][outer-container-width-style]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Outer Container Width"
                  value={
                    section.options["structure"]["outer-container-width-style"]
                      ? section.options["structure"][
                          "outer-container-width-style"
                        ]
                      : ""
                  }
                  options={[
                    { value: "", text: "100%" },
                    { value: "custom", text: "Custom" },
                  ]}
                  device="desktop"
                  condition={row_element !== undefined}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][outer-container-width-style-mobile]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Outer Container Width"
                  value={
                    section.options["structure"][
                      "outer-container-width-style-mobile"
                    ]
                      ? section.options["structure"][
                          "outer-container-width-style-mobile"
                        ]
                      : ""
                  }
                  options={[
                    { value: "", text: "100%" },
                    { value: "custom", text: "Custom" },
                  ]}
                  device="mobile"
                  condition={row_element !== undefined}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][outer-container-width]"
                  object_type="sections"
                  object_id={section.id}
                  setting_type="range"
                  value={
                    section.options["structure"]["outer-container-width"]
                      ? section.options["structure"]["outer-container-width"]
                      : undefined
                  }
                  label="Outer Container Width"
                  min={1}
                  max={2560}
                  step={1}
                  device="desktop"
                  condition={
                    row_element !== undefined &&
                    section.options["structure"][
                      "outer-container-width-style"
                    ] == "custom"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][outer-container-width-mobile]"
                  object_type="sections"
                  object_id={section.id}
                  setting_type="range"
                  value={
                    section.options["structure"]["outer-container-width-mobile"]
                      ? section.options["structure"][
                          "outer-container-width-mobile"
                        ]
                      : undefined
                  }
                  label="Outer Container Width"
                  min={1}
                  max={2560}
                  step={1}
                  device="mobile"
                  condition={
                    row_element !== undefined &&
                    section.options["structure"][
                      "outer-container-width-style-mobile"
                    ] == "custom"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][column-container]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Inner Container Width"
                  value={
                    section.options["structure"]["column-container"]
                      ? section.options["structure"]["column-container"]
                      : ""
                  }
                  options={[
                    { value: "full", text: "No Padding (Full Width)" },
                    { value: "page", text: "Padded" },
                    { value: "custom", text: "Custom" },
                  ]}
                  condition={
                    section.step_id &&
                    (cta.cta_type == "page" ||
                      cta.cta_type == "bar" ||
                      cta.cta_type == "inline" ||
                      cta.cta_type == "overlay")
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][inner-container-max-width]"
                  object_type="sections"
                  object_id={section.id}
                  setting_type="range"
                  value={
                    section.options["structure"]["inner-container-max-width"]
                      ? section.options["structure"][
                          "inner-container-max-width"
                        ]
                      : undefined
                  }
                  label="Inner Container Max Width"
                  min={1}
                  max={2560}
                  step={1}
                  device="desktop"
                  condition={
                    section.options["structure"]["column-container"] == "custom"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  object_type="sections"
                  object_id={section.id}
                  setting_name="[options][structure][inner-container-max-width-mobile]"
                  setting_type="range"
                  value={
                    section.options["structure"][
                      "inner-container-max-width-mobile"
                    ]
                      ? section.options["structure"][
                          "inner-container-max-width-mobile"
                        ]
                      : undefined
                  }
                  label="Inner Container Max Width"
                  min={1}
                  max={2560}
                  step={1}
                  device="mobile"
                  condition={
                    section.options["structure"]["column-container"] == "custom"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][browser-height]"
                  setting_type="checkbox"
                  object_type="sections"
                  object_id={section.id}
                  label="Expand to window height"
                  value={
                    section.options["structure"]["browser-height"]
                      ? section.options["structure"]["browser-height"]
                      : "false"
                  }
                  tooltip="Make this section expand to the height of the browser window"
                  condition={
                    !section.element_id &&
                    (cta.cta_type == "page" ||
                      ((cta.cta_type == "inline" ||
                        cta.cta_type == "overlay") &&
                        (variant["options"]["container-width"] == "100%" ||
                          variant["options"]["container-width"] == "2560px")))
                  }
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][browser-height-mobile]"
                  setting_type="checkbox"
                  object_type="sections"
                  object_id={section.id}
                  label="Expand to window height"
                  value={
                    section.options["structure"]["browser-height-mobile"]
                      ? section.options["structure"]["browser-height-mobile"]
                      : "false"
                  }
                  tooltip="Make this section expand to the height of the browser window"
                  condition={
                    !section.element_id &&
                    (cta.cta_type == "page" ||
                      ((cta.cta_type == "inline" ||
                        cta.cta_type == "overlay") &&
                        (variant["options"]["container-width"] == "100%" ||
                          variant["options"]["container-width"] == "2560px")))
                  }
                  device="mobile"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][container-SPACING_TYPE-"
                  setting_type="spacing"
                  object_type="sections"
                  object_id={section.id}
                  label={"Spacing"}
                  condition={contextData.device == "desktop"}
                  note="Click to increase and reduce spacing"
                  padding_only={section.element_id == undefined}
                >
                  {section.element_id && (
                    <div
                      className="row hard-center"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="col-xs-12">
                        <div className="mb-[5px] text-xl text-black dark:text-white">
                          Outer margin
                        </div>
                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-left]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-left"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Left"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />

                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-top]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"]["container-margin-top"]
                          }
                          placeholder="0px"
                          col="3"
                          note="Top"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />

                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-bottom]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-bottom"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Bottom"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />

                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-right]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-right"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Right"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="row hard-center"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Inner padding
                      </div>
                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-left]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={builder.helpers.convertLegacyPadding(
                          section.options["structure"]["container-padding-left"]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Left"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-top]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={builder.helpers.convertLegacyPadding(
                          section.options["structure"]["container-padding-top"]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Top"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-bottom]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={builder.helpers.convertLegacyPadding(
                          section.options["structure"][
                            "container-padding-bottom"
                          ]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Bottom"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-right]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={builder.helpers.convertLegacyPadding(
                          section.options["structure"][
                            "container-padding-right"
                          ]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Right"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />
                    </div>
                  </div>
                </VariantBuilderSetting>

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][container-SPACING_TYPE-mobile-"
                  setting_type="spacing"
                  object_type="sections"
                  object_id={section.id}
                  label={
                    section.element_id
                      ? "Outer Margin & Inner Padding"
                      : "Section Padding"
                  }
                  condition={contextData.device == "mobile"}
                  device="mobile"
                  note="Click to increase and reduce spacing"
                  padding_only={section.element_id == undefined}
                >
                  {section.element_id && (
                    <div
                      className="row hard-center"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="col-xs-12">
                        <div className="mb-[5px] text-xl text-black dark:text-white">
                          Outer margin{" "}
                          <span
                            className="glyphicon glyphicon-phone tooltip-icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Mobile only"
                            aria-hidden="true"
                            style={{
                              color: "#00b593",
                              fontSize: "14px",
                            }}
                          ></span>
                        </div>
                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-mobile-left]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-mobile-left"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Left"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />

                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-mobile-top]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-mobile-top"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Top"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />

                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-mobile-bottom]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-mobile-bottom"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Bottom"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />

                        <VariantBuilderSetting
                          object={section}
                          setting_name="[options][structure][container-margin-mobile-right]"
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            section.options["structure"][
                              "container-margin-mobile-right"
                            ]
                          }
                          placeholder="0px"
                          col="3"
                          note="Right"
                          classNames="spacing-inputs"
                          allow_empty={true}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="row hard-center"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Inner padding{" "}
                        <span
                          className="glyphicon glyphicon-phone tooltip-icon"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Mobile only"
                          aria-hidden="true"
                          style={{
                            color: "#00b593",
                            fontSize: "14px",
                          }}
                        ></span>
                      </div>
                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-mobile-left]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-mobile-left"
                            ]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-left"
                            ]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Left"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-mobile-top]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-mobile-top"
                            ]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-top"
                            ]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Top"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-mobile-bottom]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-mobile-bottom"
                            ]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-bottom"
                            ]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Bottom"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_name="[options][structure][container-padding-mobile-right]"
                        setting_type="number"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-mobile-right"
                            ]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            section.options["structure"][
                              "container-padding-right"
                            ]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Right"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />
                    </div>
                  </div>
                </VariantBuilderSetting>
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse name="Background" open={false}>
                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-opacity]"
                  setting_type="range"
                  object_type="sections"
                  object_id={section.id}
                  label={
                    "Background Overlay Opacity " +
                    (section.options["structure"]["background-opacity"]
                      ? section.options["structure"]["background-opacity"]
                      : 1)
                  }
                  value={
                    section.options["structure"]["background-opacity"]
                      ? section.options["structure"]["background-opacity"]
                      : 1
                  }
                  condition={["button", "form"].includes(cta.cta_type) == false}
                  min={0}
                  max={1}
                  range_step={"0.01"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-color]"
                  setting_type="color_v2"
                  object_type="sections"
                  object_id={section.id}
                  label="Background Overlay Color"
                  value={
                    section.options["structure"]["background-color"]
                      ? section.options["structure"]["background-color"]
                      : ""
                  }
                  gradient={true}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-image]"
                  setting_type="image"
                  object_type="sections"
                  object_id={section.id}
                  label="Background Image"
                  value={
                    section.options["structure"]["background-image"] &&
                    section.options["structure"]["background-image"] !== "none"
                      ? section.options["structure"]["background-image"]
                      : ""
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-position]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Background Image Position"
                  value={
                    section.options["structure"]["background-position"]
                      ? section.options["structure"]["background-position"]
                      : "top-center"
                  }
                  options={[
                    { value: "top-left", text: "Top left" },
                    { value: "top-center", text: "Top center" },
                    { value: "top-right", text: "Top right" },
                    { value: "center-left", text: "Middle left" },
                    { value: "center-center", text: "Middle center" },
                    { value: "center-right", text: "Middle right" },
                    { value: "bottom-left", text: "Bottom left" },
                    { value: "bottom-center", text: "Bottom center" },
                    { value: "bottom-right", text: "Bottom right" },
                  ]}
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-position-mobile]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Background Image Position"
                  value={
                    section.options["structure"]["background-position-mobile"]
                      ? section.options["structure"][
                          "background-position-mobile"
                        ]
                      : section.options["structure"]["background-position"]
                      ? section.options["structure"]["background-position"]
                      : "top-center"
                  }
                  options={[
                    { value: "top-left", text: "Top left" },
                    { value: "top-center", text: "Top center" },
                    { value: "top-right", text: "Top right" },
                    { value: "center-left", text: "Middle left" },
                    { value: "center-center", text: "Middle center" },
                    { value: "center-right", text: "Middle right" },
                    { value: "bottom-left", text: "Bottom left" },
                    { value: "bottom-center", text: "Bottom center" },
                    { value: "bottom-right", text: "Bottom right" },
                  ]}
                  device="mobile"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-size]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Background Image Size"
                  value={
                    section.options["structure"]["background-size"]
                      ? section.options["structure"]["background-size"]
                      : "cover"
                  }
                  options={[
                    { value: "auto", text: "Image width" },
                    { value: "cover", text: "Cover container" },
                    { value: "expand-width", text: "Expand to width" },
                    { value: "expand-height", text: "Expand to height" },
                  ]}
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-size-mobile]"
                  setting_type="dropdown"
                  object_type="sections"
                  object_id={section.id}
                  label="Background Image Size"
                  value={
                    section.options["structure"]["background-size-mobile"]
                      ? section.options["structure"]["background-size-mobile"]
                      : section.options["structure"]["background-size"]
                      ? section.options["structure"]["background-size"]
                      : "cover"
                  }
                  options={[
                    { value: "auto", text: "Image width" },
                    { value: "cover", text: "Cover container" },
                    { value: "expand-width", text: "Expand to width" },
                    { value: "expand-height", text: "Expand to height" },
                  ]}
                  device="mobile"
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][background-video]"
                  setting_type="text"
                  object_type="sections"
                  object_id={section.id}
                  label="Video Background"
                  value={
                    section.options["structure"]["background-video"]
                      ? section.options["structure"]["background-video"]
                      : ""
                  }
                  placeholder="http://"
                  note="YouTube, Vimeo or Wistia URLs. Desktop only."
                />
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse name="Border" open={false}>
                <VariantBuilderSetting
                  object={section}
                  label="Border Style"
                  setting_name="[options][structure][section-border-style]"
                  setting_type="border_style"
                  object_type="sections"
                  object_id={section.id}
                  value={
                    section.options["structure"]["section-border-style"]
                      ? section.options["structure"]["section-border-style"]
                      : "solid"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  label="Container Border Color"
                  setting_name={"[options][structure][section-border-color]"}
                  setting_type="color_v2"
                  object_type="sections"
                  object_id={section.id}
                  value={
                    section.options["structure"]["section-border-color"]
                      ? section.options["structure"]["section-border-color"]
                      : "#CCC"
                  }
                  condition={
                    section.options["structure"]["section-border-style"] !==
                    "none"
                  }
                />

                {section.options["structure"]["section-border-style"] !==
                  "none" && (
                  <div
                    className="col-xs-12 some-vertical-space setting"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="mb-[5px] text-xl text-black dark:text-white">
                          Container Border Width
                        </div>

                        {["left", "top", "bottom", "right"].map((side) => {
                          const settingName = `[options][structure][section-border-width-${side}]`;
                          return (
                            <VariantBuilderSetting
                              key={settingName}
                              object={section}
                              setting_name={settingName}
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={
                                _.isNil(
                                  section.options["structure"][
                                    `section-border-width-${side}`
                                  ]
                                ) == false
                                  ? section.options["structure"][
                                      `section-border-width-${side}`
                                    ]
                                  : "0"
                              }
                              placeholder="0px"
                              col="3"
                              note={window.capitalizeFirstLetter(side)}
                              classNames="spacing-inputs"
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <VariantBuilderSetting
                  object={section}
                  label="Container Corner Rounding"
                  setting_name={"[options][structure][section-border-radius]"}
                  setting_type="border_radius"
                  object_type="sections"
                  object_id={section.id}
                  value={
                    section.options["structure"]["section-border-radius"]
                      ? section.options["structure"]["section-border-radius"]
                      : "0"
                  }
                  valueMappings={{
                    none: "0",
                    some: "4",
                    full: "1000",
                    custom: "custom",
                  }}
                />

                <div className="col-xs-12">
                  {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                    (side) => {
                      const settingName = `[options][structure][section-border-radius-${side}]`;
                      return (
                        <VariantBuilderSetting
                          key={settingName}
                          object={section}
                          setting_name={settingName}
                          setting_type="number"
                          object_type="sections"
                          object_id={section.id}
                          value={
                            _.isNil(
                              section.options["structure"][
                                `section-border-radius-${side}`
                              ]
                            ) == false
                              ? section.options["structure"][
                                  `section-border-radius-${side}`
                                ]
                              : "0"
                          }
                          condition={
                            section.options["structure"][
                              "section-border-radius"
                            ] == "custom"
                          }
                          min={0}
                          note={capitalizeFirstLetter(side).replace("-", " ")}
                          col="3"
                          placeholder="0px"
                          classNames="spacing-inputs"
                        />
                      );
                    }
                  )}
                </div>
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse name="Shadow" open={false}>
                <VariantBuilderSetting
                  object={section}
                  label="Drop Shadow"
                  setting_name="[options][structure][box-shadow]"
                  setting_type="shadow"
                  object_type="sections"
                  object_id={section.id}
                  value={
                    section.options["structure"]["box-shadow"]
                      ? section.options["structure"]["box-shadow"]
                      : "none"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][box-shadow-horizontal-distance]"
                  setting_type="range"
                  object_type="sections"
                  object_id={section.id}
                  label={"Shadow Horizontal Distance"}
                  value={
                    section.options["structure"][
                      "box-shadow-horizontal-distance"
                    ]
                      ? section.options["structure"][
                          "box-shadow-horizontal-distance"
                        ]
                      : "0"
                  }
                  condition={
                    section.options["structure"]["box-shadow"] == "custom"
                  }
                  min={-100}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][box-shadow-vertical-distance]"
                  setting_type="range"
                  object_type="sections"
                  object_id={section.id}
                  label={"Shadow Vertical Distance"}
                  value={
                    section.options["structure"]["box-shadow-vertical-distance"]
                      ? section.options["structure"][
                          "box-shadow-vertical-distance"
                        ]
                      : "0"
                  }
                  condition={
                    section.options["structure"]["box-shadow"] == "custom"
                  }
                  min={-100}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][box-shadow-blur-radius]"
                  setting_type="range"
                  object_type="sections"
                  object_id={section.id}
                  label={"Shadow Blur Radius"}
                  value={
                    section.options["structure"]["box-shadow-blur-radius"]
                      ? section.options["structure"]["box-shadow-blur-radius"]
                      : "0"
                  }
                  condition={
                    section.options["structure"]["box-shadow"] == "custom"
                  }
                  min={0}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][box-shadow-spread-radius]"
                  setting_type="range"
                  object_type="sections"
                  object_id={section.id}
                  label={"Shadow Spread Radius"}
                  value={
                    section.options["structure"]["box-shadow-spread-radius"]
                      ? section.options["structure"]["box-shadow-spread-radius"]
                      : "0"
                  }
                  condition={
                    section.options["structure"]["box-shadow"] == "custom"
                  }
                  min={0}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  label="Shadow Color"
                  setting_name="[options][structure][box-shadow-color]"
                  setting_type="color_v2"
                  object_type="sections"
                  object_id={section.id}
                  value={
                    section.options["structure"]["box-shadow-color"]
                      ? section.options["structure"]["box-shadow-color"]
                      : "rgba(0,0,0, 0.35)"
                  }
                  condition={
                    section.options["structure"]["box-shadow"] == "custom"
                  }
                />
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse name="Custom Code" open={false}>
                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][structure][custom-class]"
                  label="Custom Classes"
                  placeholder="Custom class names here"
                  setting_type="text"
                  object_type="sections"
                  object_id={section.id}
                  value={section.options["structure"]["custom-class"]}
                  alphanumeric={true}
                  update_on_input_end={true}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_type="message"
                  label="HTML/Anchor ID"
                  value={"#section" + section.id}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_type="message"
                  label="CSS Selector"
                  value={"#section" + section.id + ".cf-section"}
                  tooltip="Using custom CSS? Make sure to add !important to the end of each of your CSS properties."
                />
              </VariantBuilderPanelCollapse>
            </React.Fragment>
          )}
          {tab == "Columns" && (
            <React.Fragment>
              {["first", "second", "third", "fourth"]
                .filter(
                  (column) =>
                    column == "first" ||
                    (column == "second" &&
                      [
                        "50-50",
                        "60-40",
                        "40-60",
                        "70-30",
                        "30-70",
                        "80-20",
                        "20-80",
                        "90-10",
                        "10-90",
                        "33-33-33",
                        "50-25-25",
                        "25-25-50",
                        "25-50-25",
                        "25-25-25-25",
                      ].includes(columnLayoutValue)) ||
                    (column == "third" &&
                      [
                        "33-33-33",
                        "50-25-25",
                        "25-25-50",
                        "25-50-25",
                        "25-25-25-25",
                      ].includes(columnLayoutValue)) ||
                    (column == "fourth" &&
                      ["25-25-25-25"].includes(columnLayoutValue))
                )
                .map((column, i) => {
                  return (
                    <VariantBuilderPanelCollapse
                      name={`Column ${i + 1}`}
                      open={
                        builder.selected().object_collapse == column
                          ? true
                          : false
                      }
                      key={"tab-section-column-" + column}
                    >
                      <VariantBuilderSetting
                        object={section}
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-SPACING_TYPE-"
                        }
                        setting_type="spacing"
                        object_type="sections"
                        object_id={section.id}
                        label="Column Padding"
                        condition={contextData.device == "desktop"}
                        note="Click to increase and reduce padding"
                        padding_only={true}
                      >
                        <div
                          className="row hard-center"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="col-xs-12">
                            <div className="mb-[5px] text-xl text-black dark:text-white">
                              Inner padding
                            </div>
                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-left]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={builder.helpers.convertLegacyPadding(
                                section.options["structure"][
                                  "cf-column-" + column + "-padding-left"
                                ]
                              )}
                              placeholder="0px"
                              col="3"
                              note="Left"
                              classNames="spacing-inputs"
                              allow_empty={true}
                            />

                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-top]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={builder.helpers.convertLegacyPadding(
                                section.options["structure"][
                                  "cf-column-" + column + "-padding-top"
                                ]
                              )}
                              placeholder="0px"
                              col="3"
                              note="Top"
                              classNames="spacing-inputs"
                              allow_empty={true}
                            />

                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-bottom]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={builder.helpers.convertLegacyPadding(
                                section.options["structure"][
                                  "cf-column-" + column + "-padding-bottom"
                                ]
                              )}
                              placeholder="0px"
                              col="3"
                              note="Bottom"
                              classNames="spacing-inputs"
                              allow_empty={true}
                            />

                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-right]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={builder.helpers.convertLegacyPadding(
                                section.options["structure"][
                                  "cf-column-" + column + "-padding-right"
                                ]
                              )}
                              placeholder="0px"
                              col="3"
                              note="Right"
                              classNames="spacing-inputs"
                              allow_empty={true}
                            />
                          </div>
                        </div>
                      </VariantBuilderSetting>

                      <VariantBuilderSetting
                        object={section}
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-SPACING_TYPE-mobile-"
                        }
                        setting_type="spacing"
                        object_type="sections"
                        object_id={section.id}
                        label="Column Padding"
                        condition={contextData.device == "mobile"}
                        device="mobile"
                        note="Click to increase and reduce padding"
                        padding_only={true}
                      >
                        <div
                          className="row hard-center"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="col-xs-12">
                            <div className="mb-[5px] text-xl text-black dark:text-white">
                              Inner padding
                            </div>
                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-mobile-left]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" +
                                      column +
                                      "-padding-mobile-left"
                                  ]
                                ) ||
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" + column + "-padding-left"
                                  ]
                                )
                              }
                              placeholder="0px"
                              col="3"
                              note="Left"
                              classNames="spacing-inputs"
                            />

                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-mobile-top]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" +
                                      column +
                                      "-padding-mobile-top"
                                  ]
                                ) ||
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" + column + "-padding-top"
                                  ]
                                )
                              }
                              placeholder="0px"
                              col="3"
                              note="Top"
                              classNames="spacing-inputs"
                            />

                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-mobile-bottom]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" +
                                      column +
                                      "-padding-mobile-bottom"
                                  ]
                                ) ||
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" + column + "-padding-bottom"
                                  ]
                                )
                              }
                              placeholder="0px"
                              col="3"
                              note="Bottom"
                              classNames="spacing-inputs"
                            />

                            <VariantBuilderSetting
                              object={section}
                              setting_name={
                                "[options][structure][cf-column-" +
                                column +
                                "-padding-mobile-right]"
                              }
                              setting_type="number"
                              object_type="sections"
                              object_id={section.id}
                              value={
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" +
                                      column +
                                      "-padding-mobile-right"
                                  ]
                                ) ||
                                builder.helpers.convertLegacyPadding(
                                  section.options["structure"][
                                    "cf-column-" + column + "-padding-right"
                                  ]
                                )
                              }
                              placeholder="0px"
                              col="3"
                              note="Right"
                              classNames="spacing-inputs"
                            />
                          </div>
                        </div>
                      </VariantBuilderSetting>

                      <VariantBuilderSetting
                        object={section}
                        setting_name={
                          "[options][structure][" +
                          column +
                          "-column-hide-mobile]"
                        }
                        setting_type="checkbox"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            column + "-column-hide-mobile"
                          ]
                            ? section.options["structure"][
                                column + "-column-hide-mobile"
                              ]
                            : "false"
                        }
                        label="Hide on mobile"
                        condition={
                          section.options["structure"]["mobile-responsive"] !==
                          "false"
                        }
                        tooltip="Want to display this only on desktop? Enable this option"
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Overlay Color"
                        setting_name={
                          "[options][structure][cf-column-" + column + "-color]"
                        }
                        setting_type="color_v2"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "cf-column-" + column + "-color"
                          ]
                            ? section.options["structure"][
                                "cf-column-" + column + "-color"
                              ]
                            : ""
                        }
                        gradient={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        label={"Overlay Opacity"}
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-opacity]"
                        }
                        setting_type="range"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "cf-column-" + column + "-opacity"
                          ]
                            ? section.options["structure"][
                                "cf-column-" + column + "-opacity"
                              ]
                            : 0
                        }
                        min={0}
                        max={1}
                        range_step={"0.01"}
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Overlay Image"
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-background]"
                        }
                        setting_type="image"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "cf-column-" + column + "-background"
                          ]
                            ? section.options["structure"][
                                "cf-column-" + column + "-background"
                              ]
                            : ""
                        }
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Overlay Image Position"
                        setting_name={
                          "[options][structure][column-" +
                          column +
                          "-background-position]"
                        }
                        setting_type="dropdown"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "column-" + column + "-background-position"
                          ]
                            ? section.options["structure"][
                                "column-" + column + "-background-position"
                              ]
                            : "top-center"
                        }
                        options={[
                          { value: "top-left", text: "Top left" },
                          { value: "top-center", text: "Top center" },
                          { value: "top-right", text: "Top right" },
                          { value: "center-left", text: "Middle left" },
                          { value: "center-center", text: "Middle center" },
                          { value: "center-right", text: "Middle right" },
                          { value: "bottom-left", text: "Bottom left" },
                          { value: "bottom-center", text: "Bottom center" },
                          { value: "bottom-right", text: "Bottom right" },
                        ]}
                        device="desktop"
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Overlay Image Position"
                        setting_name={
                          "[options][structure][column-" +
                          column +
                          "-background-position-mobile]"
                        }
                        setting_type="dropdown"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "column-" + column + "-background-position-mobile"
                          ]
                            ? section.options["structure"][
                                "column-" +
                                  column +
                                  "-background-position-mobile"
                              ]
                            : section.options["structure"][
                                "column-" + column + "-background-position"
                              ]
                            ? section.options["structure"][
                                "column-" + column + "-background-position"
                              ]
                            : "top-center"
                        }
                        options={[
                          { value: "top-left", text: "Top left" },
                          { value: "top-center", text: "Top center" },
                          { value: "top-right", text: "Top right" },
                          { value: "center-left", text: "Middle left" },
                          { value: "center-center", text: "Middle center" },
                          { value: "center-right", text: "Middle right" },
                          { value: "bottom-left", text: "Bottom left" },
                          { value: "bottom-center", text: "Bottom center" },
                          { value: "bottom-right", text: "Bottom right" },
                        ]}
                        device="mobile"
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Overlay Image Size"
                        setting_name={
                          "[options][structure][column-" +
                          column +
                          "-background-size]"
                        }
                        setting_type="dropdown"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "column-" + column + "-background-size"
                          ]
                            ? section.options["structure"][
                                "column-" + column + "-background-size"
                              ]
                            : "cover"
                        }
                        options={[
                          { value: "auto", text: "Image width" },
                          { value: "cover", text: "Cover container" },
                          { value: "expand-width", text: "Expand to width" },
                          { value: "expand-height", text: "Expand to height" },
                        ]}
                        device="desktop"
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Overlay Image Size"
                        setting_name={
                          "[options][structure][column-" +
                          column +
                          "-background-size-mobile]"
                        }
                        setting_type="dropdown"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "column-" + column + "-background-size-mobile"
                          ]
                            ? section.options["structure"][
                                "column-" + column + "-background-size-mobile"
                              ]
                            : section.options["structure"][
                                "column-" + column + "-background-size"
                              ]
                            ? section.options["structure"][
                                "column-" + column + "-background-size"
                              ]
                            : "cover"
                        }
                        options={[
                          { value: "auto", text: "Image width" },
                          { value: "cover", text: "Cover container" },
                          { value: "expand-width", text: "Expand to width" },
                          { value: "expand-height", text: "Expand to height" },
                        ]}
                        device="mobile"
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Column Border Style"
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-border-style]"
                        }
                        setting_type="border_style"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "cf-column-" + column + "-border-style"
                          ]
                            ? section.options["structure"][
                                "cf-column-" + column + "-border-style"
                              ]
                            : "solid"
                        }
                      />

                      <VariantBuilderSetting
                        object={section}
                        label="Column Border Color"
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-border-color]"
                        }
                        setting_type="color_v2"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "cf-column-" + column + "-border-color"
                          ]
                            ? section.options["structure"][
                                "cf-column-" + column + "-border-color"
                              ]
                            : "#CCC"
                        }
                        condition={
                          section.options["structure"][
                            "cf-column-" + column + "-border-style"
                          ] !== "none"
                        }
                      />

                      {section.options["structure"][
                        "cf-column-" + column + "-border-style"
                      ] !== "none" && (
                        <div
                          className="col-xs-12 some-vertical-space setting"
                          style={{ marginBottom: "5px" }}
                        >
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="mb-[5px] text-xl text-black dark:text-white">
                                Column Border Width
                              </div>

                              {["left", "top", "bottom", "right"].map(
                                (side) => {
                                  const settingName = `[options][structure][cf-column-${column}-border-width-${side}]`;
                                  return (
                                    <VariantBuilderSetting
                                      key={settingName}
                                      object={section}
                                      setting_name={settingName}
                                      setting_type="number"
                                      object_type="sections"
                                      object_id={section.id}
                                      value={
                                        _.isNil(
                                          section.options["structure"][
                                            `cf-column-${column}-border-width-${side}`
                                          ]
                                        ) == false
                                          ? section.options["structure"][
                                              `cf-column-${column}-border-width-${side}`
                                            ]
                                          : section.options["structure"][
                                              `cf-column-${side}-border-width`
                                            ]
                                          ? section.options["structure"][
                                              `cf-column-${side}-border-width`
                                            ].replace("px", "")
                                          : "0"
                                      }
                                      placeholder="0px"
                                      col="3"
                                      note={window.capitalizeFirstLetter(side)}
                                      classNames="spacing-inputs"
                                      min={0}
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <VariantBuilderSetting
                        object={section}
                        setting_name={
                          "[options][structure][cf-column-" +
                          column +
                          "-custom-class]"
                        }
                        label="Custom Classes"
                        placeholder="Custom class names here"
                        setting_type="text"
                        object_type="sections"
                        object_id={section.id}
                        value={
                          section.options["structure"][
                            "cf-column-" + column + "-custom-class"
                          ]
                        }
                        alphanumeric={true}
                        update_on_input_end={true}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_type="message"
                        label="HTML/Anchor ID"
                        value={"#section" + section.id + "-column-" + column}
                      />

                      <VariantBuilderSetting
                        object={section}
                        setting_type="message"
                        label="CSS Selector"
                        value={
                          "#section" +
                          section.id +
                          "-column-first.cf-column-" +
                          column
                        }
                        tooltip="Using custom CSS? Make sure to add !important to the end of each of your CSS properties."
                      />
                    </VariantBuilderPanelCollapse>
                  );
                })}
            </React.Fragment>
          )}
        </TabGroup>
      </div>
    </React.Fragment>
  );
};

export default SectionSettings;
