import React, { useState, createContext } from "react";
import _, { xor, update } from "lodash";
import { object } from "prop-types";
import apiRequest from "../../utils/apiRequest";

export const VariantContextData = createContext();

export const VariantBuilderContext = (props) => {
  // define the builder's context object
  const queryParameters = new URLSearchParams(window.location.search);

  let context = {
    selected_object_type: null,
    selected_object_id: null,
    selected_object_path: null,
    selected_object_tab: null,
    selected_object_collapse: null,
    selected_object_editing: false,
    objects: {},
    history: {
      changes: [],
      undos: 0,
      unsaved: null,
      unpublished: null,
    },
    device:
      queryParameters.get("device") ||
      getCookie("cf-builder-current-device") ||
      "desktop",
    panel_closed: false,
    dark_mode: getCookie("darkMode") ? getCookie("darkMode") == "true" : false,
    zoomable_canvas_locked: getCookie("zoomableCanvasLocked") ? getCookie("zoomableCanvasLocked") == "true" : false,
    view: "funnel",
    new_object: {},
  };

  if (props.variant.options["unpublished"] == "true") {
    context.history.unpublished = true;
  }

  context.logo = context.dark_mode ? props.logo_white : props.logo;
  context.objects["cta"] = props.cta;
  context.objects["variant"] = props.variant;
  context.objects["website"] = props.website;
  context.objects["users"] = props.users;
  context.objects["current_user"] = props.current_user;
  context.objects["subscription"] = props.subscription;
  context.objects["account"] = props.account;

  // set context object as state
  const [contextData, setContextData] = useState(context);

  let builder = {
    mobile: function () {
      let updatedBuilder = { ...contextData };
      updatedBuilder.device = "mobile";
      setContextData((originalData) => updatedBuilder);
      setCookie("cf-builder-current-device", "mobile");
    },

    desktop: function () {
      let updatedBuilder = { ...contextData };
      updatedBuilder.device = "desktop";
      setContextData((originalData) => updatedBuilder);
      setCookie("cf-builder-current-device", "desktop");
    },

    panel: function (closed) {
      let updatedBuilder = { ...contextData };
      updatedBuilder.panel_closed = closed;
      setContextData((originalData) => updatedBuilder);

      window.panel_closed = closed;
    },

    // builder.select populates panel settings based on the selected object
    select: function (
      object_type,
      object_id,
      tab,
      collapse,
      editing = false,
      close_panel
    ) {
      let updatedBuilder = { ...contextData };

      updatedBuilder["selected_layer_object_type"] = object_type;
      updatedBuilder["selected_layer_object_id"] = object_id;
      updatedBuilder["selected_object_type"] = object_type;
      updatedBuilder["selected_object_id"] = object_id;
      updatedBuilder["selected_object_editing"] = editing ? editing : false;
      updatedBuilder["view"] =
        object_type == "variants"
          ? "settings"
          : object_type == "steps"
          ? "funnel"
          : "layers";

      updatedBuilder["selected_object_path"] =
        object_type !== "variants"
          ? `[objects][variant][${object_type}][${object_id}]`
          : "[objects][variant]";

      updatedBuilder["selected_object_tab"] = tab ? tab : null;
      updatedBuilder["selected_object_collapse"] = collapse ? collapse : null;

      if (close_panel !== null && close_panel !== undefined) {
        updatedBuilder["panel_closed"] = close_panel;
        window.panel_closed = close_panel;
      }

      updatedBuilder.selected_item_parent_id = null;
      updatedBuilder.selected_item_id = null;
      updatedBuilder.selected_item_type = null;
      setContextData((originalData) => updatedBuilder);

      window.selected_object_type = object_type;
      window.selected_object_id = object_id;
      window.selected_object_tab = updatedBuilder.selected_object_tab;
      window.selected_object_collapse = updatedBuilder.selected_object_collapse;
      window.selected_object_editing = updatedBuilder.selected_object_editing;

      document
        .querySelectorAll(
          `.editor-select, .select-label:not([data-object_id="${object_id}"]), .select-label-popover:not([data-object_id="${object_id}"])`
        )
        .forEach((selected) => {
          if ($(selected).is(":visible") == false) return;

          if (selected.classList.contains("editor-select")) {
            if (selected.getAttribute('data-object_id') !== object_id + '' && selected.getAttribute('data-object-id') !== object_id + '') {
              selected.classList.remove("editor-select");
            }
          }

          if (
            selected.classList.contains("select-label") ||
            selected.classList.contains("select-label-popover")
          ) {
            selected.style.display = "none";
          }
        });

      onboard.checklist.close();
    },

    get_object_parent_layer: function (object_type, object_id) {
      let object = _.get(contextData.objects.variant, `${object_type}.${object_id}`, undefined);
      let parent_object = null;
      let parent_object_type = null;

      if (object_type == "elements") {
        parent_object = contextData.objects.variant.sections[object.section_id];
        parent_object_type = "sections";
      } else if (object_type == "sections") {
        let section = contextData.objects.variant.sections[object_id];

        if (section.content_toggle_item_id) {
          parent_object = contextData.objects.variant.content_toggle_items[section.content_toggle_item_id];
          parent_object_type = "content_toggle_items";
        } else if (section.element_id) {
          parent_object = contextData.objects.variant.elements[section.element_id];
          parent_object_type = "elements";
        } else {
          parent_object = contextData.objects.variant.steps[section.step_id];
          parent_object_type = "steps";
        }
      } else if (object_type == "content_toggle_items") {
        parent_object = contextData.objects.variant.elements[object.element_id];
        parent_object_type = "elements";
      }

      return {
        object: parent_object,
        object_type: parent_object_type,
      };
    },

    selected_object_layer: function (object_type, object_id) {
      let cachedLayerTree = JSON.parse(localStorage.getItem(`layerTree`));

      if (cachedLayerTree && cachedLayerTree.object_id == object_id) {
        return cachedLayerTree.object;
      } else {
        let current_object_loop_type = object_type;
        let current_object_loop_id = object_id;

        let object = _.get(contextData.objects.variant, `${current_object_loop_type}.${current_object_loop_id}`, undefined);
        let element_index = 0;

        const layerTree = new LayerTreeService(element_index, object);

        while (true) {
          let parent_layer = builder.get_object_parent_layer(current_object_loop_type, current_object_loop_id);

          if (!parent_layer.object) {
            break;
          }

          layerTree.insert(element_index, element_index + 1, parent_layer.object);

          element_index++;
          current_object_loop_type = parent_layer.object_type;
          current_object_loop_id = parent_layer.object.id;
        }

        const orderedTree = [...layerTree.inOrderTraversal()].map(x => x.value);
        localStorage.setItem(`layerTree`, JSON.stringify({ object_id, object: orderedTree }));

        return orderedTree;
      }
    },

    selected: function () {
      return {
        object: _.get(contextData, contextData.selected_object_path, undefined),
        object_type: contextData.selected_object_type,
        object_id: contextData.selected_object_id,
        object_collapse: contextData.selected_object_collapse,
        object_tab: contextData.selected_object_tab,
      };
    },

    // called when a setting is adjusted
    update: function (builderUpdates) {
      let updatedBuilder = { ...contextData };
      let historyUpdates = [];

      // for each change, update the builder state and add change to history
      builderUpdates.forEach((change) => {
        let object_path = "[objects][variant]";

        if (change.object_type !== "variants") {
          object_path += "[" + change.object_type + "]";

          if (change.object_id) {
            object_path += "[" + change.object_id + "]";
          }
        }

        let object = _.get(updatedBuilder, object_path);
        const setting_path = object_path + change.setting_name;
        const old_value = change.old_value
          ? change.old_value
          : _.get(updatedBuilder, setting_path);

        updatedBuilder = _.set(updatedBuilder, setting_path, change.value);
        updatedBuilder = _.set(
          updatedBuilder,
          object_path + "[toBeUpdated]",
          true
        );
        updatedBuilder = _.set(
          updatedBuilder,
          object_path + "[lastUpdated]",
          Date.now()
        );

        updatedBuilder["selected_object_editing"] = false;

        if (change.skip_history !== true) {
          historyUpdates.push({
            object_type: change.object_type,
            object_id: change.object_id,
            setting_name: change.setting_name,
            value: change.value,
            old_value: old_value,
          });
        }
      });

      if (historyUpdates.length > 0) {
        updatedBuilder = addChangeToHistory(updatedBuilder, {
          updates: historyUpdates,
          timestamp: Date.now(),
        });
      }

      setContextData((originalData) => updatedBuilder);
      builder.clearUnsavedLoop();
      builder.setUnsavedLoop();
    },

    // makes API POST and stores object in context
    create: function (object_type, payload, options) {
      const api_endpoint = "./" + object_type + "/";

      apiRequest("POST", api_endpoint, payload, (data) => {
        if (data && !data.error) {
          const skip_history = options && options.skip_history;

          if (options && options.transform) {
            data = options.transform(data);
          }

          builder.addObjectToBuilder(
            object_type,
            data,
            skip_history,
            options ? options.updates : undefined,
            options && options.forceRender ? true : false
          );

          if (options && options.callback) {
            options.callback(data);
          }

          if (
            skip_history !== true &&
            ["steps", "sections", "elements"].includes(object_type) &&
            options.skip_select !== true
          ) {
            builder.select(object_type, data.id);
          }
        }
      });
    },

    // marks the object to be deleted, adds removal to history
    remove: function (object_type, object_id, message, updates) {
      if (!message || confirm(message)) {
        let updatedBuilder = { ...contextData };
        let object = updatedBuilder.objects.variant[object_type][object_id];
        updates = updates ? updates : [];

        if (object) {
          object.toBeDeleted = true;
          updates.push({
            object_type: object_type,
            object_id: object_id,
            setting_name: "[toBeDeleted]",
            value: true,
            old_value: undefined,
          });

          updatedBuilder = addChangeToHistory(updatedBuilder, {
            updates: updates,
            timestamp: Date.now(),
          });

          const selectedObjectType = updatedBuilder["selected_object_type"];
          const selectedObjectId = updatedBuilder["selected_object_id"];
          if (
            selectedObjectType == object_type &&
            selectedObjectId == object_id
          ) {
            updatedBuilder["selected_object_type"] = null;
            updatedBuilder["selected_object_id"] = null;
          }

          if (
            !["actions", "conditions", "fields", "action_groups"].includes(
              object_type
            )
          ) {
            updatedBuilder["view"] =
              object_type == "steps" ? "funnel" : "layers";
          }
          updatedBuilder["panel_closed"] = false;

          setContextData((originalData) => updatedBuilder);
          builder.setUnsavedLoop();
        }
      }
    },

    // undo last update, increment undo index
    undo: function () {
      let updatedBuilder = { ...contextData };
      let undosIndex = updatedBuilder.history.undos;
      let change = updatedBuilder.history.changes[undosIndex];
      let fallbackToLayers = false;

      if (change) {
        change.updates.forEach((update, i) => {
          let object_path = "[objects][variant]";

          if (update.object_type !== "variants") {
            object_path += "[" + update.object_type + "]";
          }
          if (update.object_id) {
            object_path += "[" + update.object_id + "]";
          }

          updatedBuilder = _.set(
            updatedBuilder,
            object_path + update.setting_name,
            update.old_value
          );

          updatedBuilder = _.set(
            updatedBuilder,
            object_path + "[lastUpdated]",
            Date.now()
          );

          if (!fallbackToLayers) {
            fallbackToLayers =
              update.setting_name == "[toBeDeleted]" &&
              ["steps", "sections", "elements"].includes(update.object_type);
          }
        });

        updatedBuilder.history.undos = updatedBuilder.history.undos + 1;
        updatedBuilder.history.unsaved = true;
        if (fallbackToLayers) updatedBuilder.view = "layers";

        setContextData((originalData) => updatedBuilder);
        builder.setUnsavedLoop();
      }
    },

    // redo last update, decrement undo index
    redo: function () {
      let updatedBuilder = { ...contextData };
      let undosIndex = updatedBuilder.history.undos - 1;
      let change = updatedBuilder.history.changes[undosIndex];
      let fallbackToLayers = false;

      if (change) {
        change.updates.forEach((update, i) => {
          let object_path = "[objects][variant]";

          if (update.object_type !== "variants") {
            object_path += "[" + update.object_type + "]";
          }
          if (update.object_id) {
            object_path += "[" + update.object_id + "]";
          }

          updatedBuilder = _.set(
            updatedBuilder,
            object_path + update.setting_name,
            update.value
          );

          updatedBuilder = _.set(
            updatedBuilder,
            object_path + "[lastUpdated]",
            Date.now()
          );

          if (!fallbackToLayers) {
            fallbackToLayers =
              update.setting_name == "[toBeDeleted]" &&
              ["steps", "sections", "elements"].includes(update.object_type);
          }
        });

        updatedBuilder.history.undos = updatedBuilder.history.undos - 1;
        updatedBuilder.history.unsaved = true;
        if (fallbackToLayers) updatedBuilder.view = "layers";

        setContextData((originalData) => updatedBuilder);
        builder.setUnsavedLoop();
      }
    },

    new_object: (payload) => {
      const noStepsAvailable =
        Object.values(contextData.objects.variant.steps).filter(
          (step) => !step.toBeDeleted
        ).length == 0;
      const noSectionsAvailable =
        Object.values(contextData.objects.variant.sections).filter(
          (section) =>
            !section.element_id &&
            !section.content_toggle_item_id &&
            !section.toBeDeleted
        ).length == 0;

      let updatedBuilder = { ...contextData };
      updatedBuilder["view"] = "create";

      if (
        ["Sections", "Elements"].includes(payload.object_type) &&
        noStepsAvailable
      ) {
        updatedBuilder["new_object"] = {
          object_type: "Steps",
        };
      } else if (payload.object_type == "Elements" && noSectionsAvailable) {
        updatedBuilder["new_object"] = {
          object_type: "Sections",
        };
      } else {
        updatedBuilder["new_object"] = {
          ...updatedBuilder["new_object"],
          ...payload,
        };
      }

      updatedBuilder["selected_object_type"] = null;
      updatedBuilder["selected_object_id"] = null;
      updatedBuilder["selected_object_collapse"] = null;
      updatedBuilder["selected_object_tab"] = null;
      setContextData((originalData) => updatedBuilder);

      window.selected_object_type = null;
      window.selected_object_id = null;
      window.selected_object_tab = null;
      window.selected_object_collapse = null;
      window.selected_object_editing = null;
    },

    // adds new object to context
    addObjectToBuilder: (
      object_type,
      object,
      skip_history,
      updates,
      forceRender
    ) => {
      let updatedBuilder = { ...contextData };
      updates = updates ? updates : [];
      object["toBeCreated"] = true;
      updatedBuilder.objects.variant[object_type][object.id] = object;

      if (skip_history !== true) {
        updates.push({
          object_type: object_type,
          object_id: object.id,
          setting_name: "[toBeDeleted]",
          value: undefined,
          old_value: true,
        });

        if (forceRender == true) {
          setContextData((originalData) => updatedBuilder);
          dispatchCustomEvent("updateBuilder", { updates: updates });
        } else {
          updatedBuilder = addChangeToHistory(updatedBuilder, {
            updates: updates,
            timestamp: Date.now(),
          });
        }
      }

      if (forceRender !== true) {
        setContextData((originalData) => updatedBuilder);
        builder.setUnsavedLoop();
      }
    },

    // saves builder state to database
    save: async function () {
      console.log("saving...");
      let button = document.getElementById("save");

      let updatedBuilder = { ...contextData };
      let hasObjectsToDelete =
        JSON.stringify(updatedBuilder).indexOf('"toBeDeleted":true') > -1;
      let saveSuccessful = true;

      if (
        hasObjectsToDelete == false ||
        confirm(
          "Before saving, are you sure you want to permanently delete the elements you've removed?\r\n\r\nWarning: Your undo history will be cleared upon saving."
        )
      ) {
        button.classList.add("disabled");
        button.innerHTML =
          '<i class="glyphicon glyphicon-repeat spin-animation" style="font-size: 12px !important; margin: 0px; font-weight: bold;"></i>';
        button.classList.add("saving");

        let updateRequests = [];
        let deleteRequests = [];
        let objectsSuccessfullyUpdated = [];
        let objectsToBeDeleted = [];

        let object_types = [
          "variant",
          "step",
          "section",
          "element",
          "field",
          "field_option",
          "content_list_item",
          "product",
          "product_variant",
          "content_toggle_item",
          "product_recommendation",
          "action_group",
          "condition",
          "action",
        ];

        object_types.forEach((object_type) => {
          let api_root;
          let objectsToBeUpdated;

          // find objects that need to be updated
          if (object_type == "variant") {
            api_root = "./";
            let variant = JSON.parse(
              JSON.stringify(updatedBuilder.objects["variant"])
            );
            let object_types_extended = [...object_types, "action"];
            object_types_extended.forEach((object_type) => {
              delete variant[object_type + "s"];
            });
            objectsToBeUpdated = [];
            variant.options["unpublished"] = "true";
            objectsToBeUpdated.push(variant);
          } else {
            api_root = "./" + object_type + "s/";
            objectsToBeUpdated = Object.values(
              updatedBuilder.objects["variant"][object_type + "s"]
            ).filter(
              (object) =>
                object.toBeUpdated == true || object.toBeCreated == true
            );
          }

          // add PUT calls to save request
          objectsToBeUpdated.forEach((object) => {
            let api_endpoint =
              object_type == "variant" ? api_root : api_root + object.id;

            let payload = {};
            payload[object_type] = object;

            if (object_type == "action") {
              payload = { action_object: payload.action };
            }

            updateRequests.push(apiRequest("PUT", api_endpoint, payload));
            objectsSuccessfullyUpdated.push(object);
          });

          // add DELETE calls to save request
          if (object_type !== "variant") {
            const objectsMarkedForDeletion = Object.values(
              updatedBuilder.objects["variant"][object_type + "s"]
            ).filter((object) => object.toBeDeleted == true);

            objectsMarkedForDeletion.forEach((object) => {
              objectsToBeDeleted.push({
                object_type: object_type,
                object_id: object.id,
                delete_path: api_root + object.id,
              });
            });
          }
        });

        if (updateRequests.length > 0) {
          const updateResults = await Promise.all(updateRequests);
          console.log("update results", updateResults);

          if (
            updateResults.filter((result) =>
              [408, 401, 500, 503].includes(result.code)
            ).length == 0
          ) {
            // register successful save with builder context
            updatedBuilder.history.unsaved = false;
            updatedBuilder.history.unpublished = true;

            objectsSuccessfullyUpdated.forEach((object) => {
              delete object["toBeUpdated"];
              delete object["toBeCreated"];
            });

            // process delete requests after updates succeeded
            if (objectsToBeDeleted.length > 0) {
              objectsToBeDeleted.forEach((objectToBeDeleted) => {
                apiRequest("DELETE", objectToBeDeleted.delete_path);
                delete updatedBuilder.objects["variant"][
                  objectToBeDeleted.object_type + "s"
                ][objectToBeDeleted.object_id];
              });

              updatedBuilder.history.changes = [];
              updatedBuilder.history.undos = 0;

              const selectedObjectType = updatedBuilder["selected_object_type"];
              const selectedObjectId = updatedBuilder["selected_object_id"];
              const selectedObject =
                selectedObjectType &&
                selectedObjectId &&
                updatedBuilder.objects["variant"][selectedObjectType]
                  ? updatedBuilder.objects["variant"][selectedObjectType][
                      selectedObjectId
                    ]
                  : null;

              if (
                !selectedObject ||
                selectedObject.toBeDeleted == true ||
                (selectedObjectType == "sections" && selectedObject.element_id)
              ) {
                updatedBuilder["selected_object_type"] = null;
                updatedBuilder["selected_object_id"] = null;
              }
            }

            setContextData((originalData) => updatedBuilder);
          } else {
            // alert user about unsuccessful save
            saveSuccessful = false;
            button.innerText = "Save";
            button.classList.remove("disabled");
            if (
              updateResults.filter((result) => [401].includes(result.code))
                .length > 0
            ) {
              alert(
                "Save unsuccessful because you are logged out, or you do not have the correct permissions. \r\n\r\nWARNING: To preserve your work, please log back in from another tab and then try saving here again."
              );
            } else if (
              updateResults.filter((result) =>
                [408, 500, 503].includes(result.code)
              ).length > 0
            ) {
              alert("Save unsuccessful. Please try again!");
            }
          }
          builder.clearUnsavedLoop();
        }
      }

      button.innerText = "Save";
      return saveSuccessful;
    },

    publish: async function () {
      let button = document.getElementById("publish");
      let updatedBuilder = { ...contextData };
      let publishSuccessful = true;

      if (
        !updatedBuilder.objects.variant.options.unpublished ||
        updatedBuilder.history.unpublished == true
      ) {
        if (button) {
          button.innerHTML =
            '<i class="glyphicon glyphicon-repeat spin-animation" style="font-size: 12px !important; margin: 0px; font-weight: bold;"></i>';
        }

        let variant = JSON.parse(
          JSON.stringify(updatedBuilder.objects.variant)
        );

        [
          "variants",
          "steps",
          "sections",
          "elements",
          "fields",
          "field_options",
          "products",
          "product_variants",
          "product_recommendations",
          "action_groups",
          "conditions",
          "actions",
        ].forEach((object_type) => {
          delete variant[object_type];
        });

        const result = await apiRequest("PUT", "./", {
          variant: variant,
          publish: true,
        });

        if (result && result.code == 200) {
          // register successful publish to builder context
          updatedBuilder.history.unsaved = false;
          updatedBuilder.history.unpublished = false;
          setContextData((originalData) => updatedBuilder);
        } else {
          // alert use about unsuccessful publish
          publishSuccessful = false;
          button.innerText = "Publish";
          button.classList.remove("disabled");
          if (result && result.code == 401) {
            alert(
              "Publish unsuccessful because you are logged out, or you do not have the correct permissions. Please log back in from another tab and then try publishing here again."
            );
          } else {
            alert("Publish unsuccessful. Please try again.");
          }
        }
      }

      button.innerText = "Publish";
      return publishSuccessful;
    },

    // alerts the user upon leaving about unsaved changes
    unsavedWarning: () => {
      let message =
        contextData.history.unsaved == true
          ? "You have unsaved changes, are you sure want to leave?"
          : "";

      let links = Array.from(document.querySelectorAll("a")).filter(
        (a) =>
          a.href &&
          a.href !== "#" &&
          a.classList.contains("builder-link") == false
      );

      links.forEach((a) => {
        a.onclick = function (e) {
          if (
            ["button", "role"].includes(a.getAttribute("role")) == false &&
            a.classList.contains("fr-command") == false &&
            a.classList.contains("shepherd-button") == false &&
            a.classList.contains("shepherd-cancel-link") == false &&
            $(a).parents(".fr-inline").length == 0 &&
            $(a).parents(".bootstrap-datetimepicker-widget").length == 0
          ) {
            if (
              window.leaving !== true &&
              contextData.history.unsaved == true &&
              confirm(message)
            ) {
              e.preventDefault();
              window.leaving = true;

              if (contextData.history.unpublished !== true) {
                builder.deleteObjectsToBeCreated();
              }

              setTimeout(function () {
                location.href = a.href;
              }, 500);
            }
          }
        };
      });

      function unloadAlert(e) {
        if (
          window.leaving !== true &&
          (contextData.history.unsaved == true ||
            contextData.history.unpublished == true)
        ) {
          e = e || window.event;
          if (e) {
            e.returnValue = message;
          }
          return message;
        }
      }

      if (environment !== "test") {
        window.addEventListener("beforeunload", unloadAlert);
      }
    },

    setUnsavedLoop: () => {
      let unsavedLoopInterval = () => {
        if (
          contextData.history.unsaved == true &&
          window.selected_object_editing !== true &&
          window.filestackOpen !== true &&
          confirm(
            "You haven't saved your changes in a while. Want to save now?"
          )
        ) {
          builder.save();
          builder.clearUnsavedLoop();
        } else {
          console.log("not saving");
          builder.clearUnsavedLoop();
          builder.setUnsavedLoop();
        }
      };

      if (typeof window.unsavedLoop == "undefined") {
        window.unsavedLoop = setInterval(unsavedLoopInterval, 600000);
      }
    },

    clearUnsavedLoop: () => {
      clearInterval(window.unsavedLoop);
      window.unsavedLoop = undefined;
    },

    // deletes unsaved created objects upon intentional navigation
    deleteObjectsToBeCreated: () => {
      let object_types = [
        "steps",
        "sections",
        "elements",
        "fields",
        "field_options",
        "content_list_items",
        "products",
        "content_toggle_items",
        "product_variants",
        "product_recommendations",
        "actions",
        "conditions",
        "action_groups",
      ];

      object_types.forEach((object_type) => {
        let objectsToBeDeleted = Object.values(
          contextData.objects["variant"][object_type]
        ).filter((object) => object.toBeCreated == true && object.id);

        objectsToBeDeleted.forEach((object) => {
          apiRequest("DELETE", "./" + object_type + "/" + object.id);
        });
      });
    },

    // shows a feature gate / upgrade prompt depending on feature type, subscription plan and website premium status
    displayFeatureGate: (event, feature_gate_type, value) => {
      const is_premium_website = contextData.objects.website.premium == true;
      const plan_type =
        window.plan_type || contextData.objects.subscription.plan_type;
      const is_super_admin =
        contextData.objects.current_user.super_admin == true;
      var modal = $("#upgrade");
      var after_2024_deprecated = $(modal).attr("data-account-after-2024-deprecation") == 'true';

      let label;
      let required_plan;
      let should_display = false;

      if (
        feature_gate_type == "Conditional Action" ||
        feature_gate_type == "Skip Logic"
      ) {
        label = "use conditional funnel logic";
        required_plan = "2024_pro";
        should_display =
          ["free", "2024_pages", "2024_core", "2024_funnels"].includes(plan_type) ||
          is_premium_website == false;
      }

      if (feature_gate_type == "Conditional Product Recommendations") {
        label = "add conditional product recommendations";
        required_plan = "2024_pro";
        should_display =
          ["free", "2024_pages", "2024_core"].includes(plan_type) ||
          (plan_type == '2024_funnels' && after_2024_deprecated) || 
          is_premium_website == false;
      }

      if (feature_gate_type == "Custom HTML") {
        label = "add custom HTML";
        required_plan = "2024_pages";
        should_display =
          (plan_type == "free" || is_premium_website == false) &&
          !is_super_admin;
      }

      if (feature_gate_type == "Custom Scripts") {
        label = "add conversion pixels, custom javascript & CSS";
        required_plan = "2024_pages";
        should_display =
          (plan_type == "free" || is_premium_website == false) &&
          !is_super_admin;
      }

      if (feature_gate_type == "Email Validation") {
        label = "enable real-time email & phone verification form fields";
        required_plan = plan_type == "2022_pro" ? "team" : "2024_plus";
        should_display =
          [
            "free",
            "2024_pages",
            "2024_core",
            "2024_funnels",
            "2024_pro",
            "marketer",
            "2021_pro",
            "2022_pro",
            "2023_build",
            "2023_launch",
          ].includes(plan_type) || is_premium_website == false;
      }

      if (feature_gate_type == "Phone Validation") {
        label = "enable real-time email & phone verification form fields";
        required_plan = "2024_plus";
        should_display =
          [
            "free",
            "2024_pages",
            "2024_core",
            "2024_funnels",
            "2024_pro",
            "2023_build",
            "2023_launch",
          ].includes(plan_type) || is_premium_website == false;
      }

      if (feature_gate_type == "Upsells & Cross-sells" && value == "cart") {
        
        label = "trigger 1-click upsells";
        required_plan = "2024_plus";
        should_display =
          (["free", "2024_pages", "2024_core", "2024_funnels"].includes(plan_type) ||
            (plan_type == '2024_pro' && after_2024_deprecated) ||
            is_premium_website == false);
      }

      if (feature_gate_type == "Upsells & Cross-sells" && value == "related") {
        label = "launch automated cross-sells";
        required_plan = "2024_plus";
        should_display =
          (["free", "2024_pages", "2024_core"].includes(plan_type) ||
            (plan_type == '2024_pro' && after_2024_deprecated) ||
            is_premium_website == false);
      }

      if (feature_gate_type == "Upsells & Cross-sells" && value == "filter_quiz_answers") {
        label = "create product quiz funnels";
        required_plan = "2024_pro";
        should_display =
          (["free", "2024_pages", "2024_core"].includes(plan_type) ||
            is_premium_website == false);
      }

      if (should_display) {
        subscriptions.upgrade_modal(
          event,
          false,
          label,
          required_plan,
          "Triggered feature CTA – " + feature_gate_type
        );
      }

      return !should_display;
    },

    helpers: {
      convertLegacyPadding: function (oldValue) {
        const levelsMap = {
          0: 0,
          1: 15,
          2: 25,
          3: 40,
          4: 70,
          5: 120,
        };

        let newValue;
        if (oldValue) {
          if (typeof oldValue == "string" && oldValue.indexOf("_") > -1) {
            let level = oldValue.split("_").pop();
            newValue = levelsMap[level];
          } else {
            newValue = oldValue;
          }
        }
        return newValue;
      },
    },

    toggleDarkMode: () => {
      contextData.dark_mode = !contextData.dark_mode;
      setCookie("darkMode", `${contextData.dark_mode}`);
      document.body.setAttribute(
        "data-mode",
        contextData.dark_mode ? "dark" : "light"
      );

      let updatedBuilder = { ...contextData };
      updatedBuilder.dark_mode = contextData.dark_mode;
      setContextData((originalData) => updatedBuilder);
    },

    toggleZoomableCanvas: () => {
      contextData.zoomable_canvas_locked = !contextData.zoomable_canvas_locked;
      setCookie("zoomableCanvasLocked", `${contextData.zoomable_canvas_locked}`);
      let updatedBuilder = { ...contextData };
      updatedBuilder.zoomable_canvas_locked = contextData.zoomable_canvas_locked;
      setContextData((originalData) => updatedBuilder);
    },

    view: (view, collapse) => {
      let updatedBuilder = { ...contextData };
      updatedBuilder.view = view;
      updatedBuilder.selected_object_type = null;
      updatedBuilder.selected_object_id = null;
      updatedBuilder.selected_object_tab = null;
      updatedBuilder.selected_object_collapse = collapse ? collapse : null;
      updatedBuilder.selected_item_parent_id = null;
      updatedBuilder.selected_item_id = null;
      updatedBuilder.selected_item_type = null;
      updatedBuilder.panel_closed = false;
      setContextData((originalData) => updatedBuilder);

      window.selected_object_type = null;
      window.selected_object_id = null;
      window.selected_object_tab = null;
      window.selected_object_collapse = collapse ? collapse : null;
      window.selected_object_editing = null;
      window.panel_closed = false;
    },

    focus: (parent_id, item_type, item_id) => {
      console.log("focus", parent_id, item_type, item_id);
      let updatedBuilder = { ...contextData };
      updatedBuilder.selected_item_parent_id = parent_id;
      updatedBuilder.selected_item_id = item_id;
      updatedBuilder.selected_item_type = item_type;
      setContextData((originalData) => updatedBuilder);
    },
  };

  // updates builder history with given change
  function addChangeToHistory(updatedBuilder, change) {
    // add to updates if no undos in state
    if (updatedBuilder.history.undos == 0) {
      updatedBuilder.history.changes.unshift(change);
    }

    // if undos in state, replace undone changes with new changes, keeping undo history
    if (updatedBuilder.history.undos > 0) {
      let new_changes = [];

      updatedBuilder.history.changes.forEach((change, i) => {
        if (i >= updatedBuilder.history.undos) {
          new_changes.unshift(change);
        }
      });

      new_changes.unshift(change);
      updatedBuilder.history.changes = new_changes;
    }

    updatedBuilder.history.undos = 0;
    updatedBuilder.history.unsaved = true;
    return updatedBuilder;
  }

  return (
    <VariantContextData.Provider value={[contextData, builder, setContextData]}>
      <React.Fragment>
        <div
          className={`row hard-center variant-edit ${
            contextData.dark_mode ? "dark" : ""
          }`}
        >
          {props.children}
        </div>
      </React.Fragment>
    </VariantContextData.Provider>
  );
};
